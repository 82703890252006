export default function formatDate(date) {
  const utcDate = new Date(date);
  // Get the local date string with specific options
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };

  const localDateString = utcDate.toLocaleString("en-US", options);
  const formattedDateString = localDateString.replace(
    /(\d+:\d+\s)(\w+)$/,
    "$1($2)",
  );
  return formattedDateString;
}

export function organizerEditDate(date) {
  if (!date) {
    return '';
  }

  if (!date.includes(':00.000Z')) {
    date += ':00.000Z';
  }
  const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  const isValidFormat = dateFormat.test(date);

  if (isValidFormat) {
    let outDate = new Date(date);
    return outDate.toISOString().slice(0, 16);
  }
  return new Date().toISOString().slice(0, 16);
}
export function formatGoogleCalendarDate (inputDate){
  if (!inputDate) {
    return '';
  }
  
  if (!inputDate.includes(':00.000Z')) {
    inputDate += ':00.000Z';
  }

  let outDate = new Date(inputDate);
  outDate = outDate.toISOString().slice(0, 16).replace(/-/gi, "/").replace("T", " ");
  const parts = outDate.split(" ");
  const parts1 = parts[0].split("/");
  const parts2 = parts[1].split(":");
  outDate = `${parts1[1]}/${parts1[2]}/${parts1[0]} ${parts2[0] % 12}:${parts2[1]} ${Math.floor(parts2[0] / 12) === 0 ? "AM" : "PM"}`;

  const date = new Date(outDate);

// Use the ISO format directly
const formattedDate = date.toISOString().replace(/[:-]/g, '');

  return formattedDate + '/' + formattedDate;

  
};

export function organizerShowDate(date) {
  if (!date) {
    return '';
  }
  
  if (!date.includes(':00.000Z')) {
    date += ':00.000Z';
  }

  let outDate = new Date(date);
  outDate = outDate.toISOString().slice(0, 16).replace(/-/gi, "/").replace("T", " ");
  const parts = outDate.split(" ");
  const parts1 = parts[0].split("/");
  const parts2 = parts[1].split(":");
  outDate = `${parts1[1]}/${parts1[2]}/${parts1[0]} ${parts2[0] % 12}:${parts2[1]} ${Math.floor(parts2[0] / 12) === 0 ? "AM" : "PM"}`;
  return outDate;
}

export function formatServerDate(date) {
  let outDate = new Date(date);
  return outDate.toISOString().slice(0, 16);
}

export function formatLocalDate(date) {
  let outDate = new Date(date);
  const utcTimestamp = outDate.getTime() - (new Date().getTimezoneOffset() * 60000);
  outDate = new Date(utcTimestamp);

  const formattedDateString = new Date(outDate).toISOString().slice(0, 16);
  return formattedDateString;
}