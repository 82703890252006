import LogoIcon from "assets/img/logo.png";
import FacebookIcon from "assets/img/facebook.png";
import InstagramIcon from "assets/img/instagram.png";
import LinkedinIcon from "assets/img/linkedin.png";
import TiktokIcon from "assets/img/tiktok.png";

export default function Footer() {
  return (
    <div className="font-poppins text-white bg-primary">
      <div className="md:container mx-auto flex flex-col sm:flex-row justify-between items-start px-[32px] sm:px-[128px] py-[64px] gap-6">
        <div className="flex flex-col justify-start items-start gap-6">
          <img alt='' src={LogoIcon} />
          <p className="text-xs">
            SriNet is a global self-service ticketing platform for<br />
            live experiences that allows anyone to create, share,<br />
            find and attend events that fuel their passions and<br />
            enrich their lives.
          </p>
          <div className="flex flex-row justify-start gap-3">
            <a href='https://www.facebook.com/profile.php?id=61552994476400' target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/></svg></a>
            <a href='https://www.linkedin.com/company/srinetevents/' target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/><rect width="4" height="12" x="2" y="9"/><circle cx="4" cy="4" r="2"/></svg></a>
            <a href='https://www.instagram.com/srinet_entertainment/' target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/></svg></a>
            <a href="https://www.youtube.com/channel/UCTjIfTTo9xbmOuB6wjYv4Og" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-youtube"><path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17"/><path d="m10 15 5-3-5-3z"/></svg></a>
            <a href='https://www.tiktok.com/@srinetevents?is_from_webapp=1&sender_device=pc' target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m12.53.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"/></svg></a>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start gap-2">
          <p className="text-2xl">Plan Events</p>
          <a href="/organizer/login"><p className="text-xs">Create and Set Up</p></a>
          {/* <p className="text-xs">Sell Tickets</p> */}
          {/* <p className="text-xs">Online RSVP</p> */}
          <a href="/events"><p className="text-xs">Online Events</p></a>
        </div>
        <div className="flex flex-col justify-start items-start gap-2">
          <p className="text-2xl">SriNet</p>
          <a href="/about"><p className="text-xs">About Us</p></a>
          <a href="/contact"><p className="text-xs">Contact Us</p></a>

          {/* <p className="text-xs">Contact Us</p> */}
          <a href={process.env.REACT_APP_DOCS_URL}><p className="text-xs">Help Center</p></a>
          {/* <p className="text-xs">How it Works</p> */}
          {/* <p className="text-xs">Terms & Conditions</p> */}
          <a href="/terms-of-service"><p className="text-xs">Terms of Service</p></a>
          <a href="/refund-policy"><p className="text-xs">24h Refund Policy</p></a>
          <a href="/no-refund"><p className="text-xs">No Refund Policy</p></a>
        </div>
        {/* <div className="flex flex-col justify-start items-start gap-2">
          <p className="text-2xl">Stay In The Loop</p>
          <span className="text-xs">
            Join our mailing list to stay in the loop for the newest events!
          </span>
          <div className="flex flex-row" >
            <input type="email" id="email" placeholder="Email address" className="text-black p-2 outline-none max-w-[225px] bg-[white]" required="required"/>
            <button type="submit" className="text-white  px-[22.5px] py-[9px] font-bold border-white border-2 shadow-lg">
              Subscribe
            </button>  
          </div>
        </div> */}
        <div id="mc_embed_shell">
          <div id="mc_embed_signup">
            <form action="https://srinet.us21.list-manage.com/subscribe/post?u=743b5dca70929a291d7ffe1d2&amp;id=9919e0a3f7&amp;f_id=0003f0e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="">
              <div className="flex flex-col justify-start items-start gap-2">
                <p className="text-2xl">Stay In The Loop</p>
                <span className="text-xs">
                  Join our mailing list to stay in the loop for the newest events!
                </span>
                <div className="mc-field-group flex flex-row">
                  <input type="email" name="EMAIL" className="text-black p-2 outline-none max-w-[225px] bg-[white]" id="mce-EMAIL" required="required" placeholder="Email address"/>
                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="text-white  px-[22.5px] py-[9px] font-bold border-white border-2 shadow-lg" value="Subscribe"/>
                  <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                </div>
              <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                <div className="response" id="mce-success-response" style={{display: "none"}}></div>
              </div>
              <div aria-hidden="true" style={{position: "absolute" ,left: "-5000px"}}>
                /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                <input type="text" name="b_743b5dca70929a291d7ffe1d2_9919e0a3f7" tabindex="-1" value=""/>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="md:container mx-auto">
        <hr className="bg-white" />
        <div className="flex items-center justify-center py-[30px]">
          <span className="text-center">Copyright © 2023 SriNet LLC</span>
        </div>
      </div>
    </div>
  );
}