import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/profile";

  const username = localStorage.getItem('email')
  const permissions = localStorage.getItem('role')

  const [user, setUser] = useState({
    username: username,
    permissions: [permissions]
  });


  const login = (user) => {
    localStorage.setItem('token', user.token);
    localStorage.setItem('refreshToken', user.refreshToken);
    localStorage.setItem('email', user.email);
    localStorage.setItem('role', user.role);
    const sourcePath = localStorage.getItem('redirection') || '/';
    localStorage.removeItem('redirection'); // Clear the stored source path

    

    setUser({ username: user.email, permissions: [user.role] });
    if (user.role === "admin") {
      navigate('/admin', { replace: true });
    } else if (user.role === "organizer") {
      navigate('/organizer', { replace: true });
    } else {
      // Redirect the user back to the source path
    navigate(sourcePath);
      //navigate(redirectPath, { replace: true });
    }
  };

  const logout = () => {
    setUser({ username: "", permissions: [] });
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};
