import { useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "provider/authProvider";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Drawer
} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import PhoneIcon from '@mui/icons-material/Phone';

import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import MobileLogoIcon from "assets/img/logo.png";
import LogoIcon from "assets/img/logo2.png";
import LogoIconDark from "assets/img/logo2.png";
import Login from "pages/login";
import { Person } from "@mui/icons-material";

export default function Header(props) {
  const { logout, user } = useAuth();
  const { theme } = props;
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState(false);

  const logoutHandler = () => {
    logout();
    
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const pages = [{
    to: '/events',
    name: 'Events',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-theater"><path d="M2 10s3-3 3-8"/><path d="M22 10s-3-3-3-8"/><path d="M10 2c0 4.4-3.6 8-8 8"/><path d="M14 2c0 4.4 3.6 8 8 8"/><path d="M2 10s2 2 2 5"/><path d="M22 10s-2 2-2 5"/><path d="M8 15h8"/><path d="M2 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1"/><path d="M14 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1"/></svg>
  }, {
    to: '/about',
    name: 'About',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-info"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/></svg>
  }, {
    to: '/contact',
    name: 'Contact',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-send"><path d="m22 2-7 20-4-9-9-4Z"/><path d="M22 2 11 13"/></svg>
  }
  ];

  const drawer = (
    <div id="mobile_drawer" className="my-auto h-[50vh]">
        <div className="w-[120px] mx-auto mb-[50px]">
          <img src={LogoIconDark} width={120} />
        </div>
      
      <List className="text-center">
        {pages.map((page, index) => (
          <ListItem key={index} component="a" onClick={() => navigate(page.to)} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {page.icon}
              </ListItemIcon>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      
      <List>
        {
          (['admin', 'organizer'].includes(user?.permissions[0]) && user.username)
            ?
            <ListItem component="a" onClick={() => navigate('/' + user.permissions[0])} disablePadding>
              <ListItemButton className="bg-danger">
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>
            :
            <></>
        }
        {
          user.username
            ?
            
            <><ListItem component="a" onClick={() => navigate('/profile')} disablePadding>
            <ListItemButton className="bg-yellow">
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          <ListItem component="a" onClick={() => navigate('/')} disablePadding>
            <ListItemButton className="bg-yellow" onClick={() => logoutHandler()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem></>
            :
            <>
              <ListItem component="a" onClick={() => navigate('/customer/login')} disablePadding>
                <ListItemButton className="bg-danger">
                  <ListItemIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user-round"><circle cx="12" cy="8" r="5"/><path d="M20 21a8 8 0 0 0-16 0"/></svg>
                  </ListItemIcon>
                  <ListItemText primary="Customer Login" />
                </ListItemButton>
              </ListItem>
              <ListItem component="a" onClick={() => navigate('/organizer/login')} disablePadding>
                <ListItemButton className="bg-yellow">
                  <ListItemIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-book-user"><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20"/><circle cx="12" cy="8" r="2"/><path d="M15 13a3 3 0 1 0-6 0"/></svg>
                  </ListItemIcon>
                  <ListItemText primary="Organizer Login" />
                </ListItemButton>
              </ListItem>
            </>
        }
      </List>
    </div>
  );
  const drawerWidth = 240;

  return (
    <>
      <div className="font-poppins text-white flex flex-col justify-between items-center md:flex-row md:justify-between md:items-center gap-3">
        <div className="w-full sm:w-[200px] flex flex-row justify-between px-3">
          <IconButton
            color={theme === "dark" ? "error" : "inherit"}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <a href="/" className="">
            <img alt='' src={theme === "dark" ? LogoIconDark : LogoIcon} className="w-[150px] hidden sm:block" />
            <img alt='' src={MobileLogoIcon} className="w-[50px] block sm:hidden" />
          </a>
        </div>
        <div className={"hidden sm:flex flex-row items-center gap-6 text-xl"}>
          <a href="/events" className={theme === "dark" ? "text-dark" : "text-white"}>Events</a>
          <a href="/about" className={theme === "dark" ? "text-dark" : "text-white"}>About</a>
          {/* <a href="/plan" className={theme === "dark" ? "text-dark" : "text-white"}>Plan</a> */}
          <a href="/contact" className={theme === "dark" ? "text-dark" : "text-white"}>Contact</a>
        </div>
        <div className="hidden sm:flex flex-col sm:flex-row items-center gap-6">
          {
            (['admin', 'organizer'].includes(user?.permissions[0]) && user.username)
              ?
              <button type="submit" className="text-white max-w-[250px] px-[22.5px] py-[9px] font-bold shadow-lg" style={{"background": "linear-gradient(90deg, #CF214E 100%, rgba(207, 33, 78, 0.00) 113.74%)"}} onClick={() => navigate('/' + user.permissions[0])}>Dashboard</button>
              :
              <></>
          }
          {
            (!['admin', 'organizer'].includes(user?.permissions[0]) && user.username)
              ?
              <button type="button" className="text-white max-w-[250px] px-[22.5px] py-[9px] font-bold shadow-lg" style={{"background": "linear-gradient(90deg, #CF214E 100%, rgba(207, 33, 78, 0.00) 113.74%)"}} onClick={() => navigate('/profile')}>Profile</button>
              :
              <></>
          }
          {
            user.username
              ?
              <>
                <button type="submit" className="text-white max-w-[250px] px-[22.5px] py-[9px] font-bold shadow-lg" style={{"background": "linear-gradient(90deg, #FEB931 100%, rgba(207, 33, 78, 0.00) 113.74%)"}} onClick={() => logoutHandler()}>Logout</button>
                </>
                :
              <>
                <button type="button" className="text-white max-w-[250px] px-[22.5px] py-[9px] font-bold shadow-lg" style={{"background": "linear-gradient(90deg, #CF214E 100%, rgba(207, 33, 78, 0.00) 113.74%)"}} onClick={() => navigate('/customer/login')}>Customer</button>
                <button type="button" className="text-white max-w-[250px] px-[22.5px] py-[9px] font-bold shadow-lg" style={{"background": "linear-gradient(90deg, #FEB931 100%, rgba(207, 33, 78, 0.00) 113.74%)"}} onClick={() => navigate('/organizer/login')}>Organizer</button>
              </>
          }
          
        </div>
      </div>
      <Box
        component="nav"
        sx={{ width: { default: 0, sm: drawerWidth } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer id="drawer_drawer"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
          
        </Drawer>
      </Box>
    </>
  );
}