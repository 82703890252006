import React, { useState } from "react";
import { useLocation } from "react-router";

import { Header } from "components";
import { useAuth } from "provider/authProvider";
import api from "config/service";
import { toast, Toaster } from "react-hot-toast";
import { Footer } from "components";
document.title = 'SriNet | Login';

export default function Login() {
  const [user, setUser] = useState({
    email: '',
    pwd: ''
  });
  const { login } = useAuth();
  const location = useLocation();

  const handleLogin = async () => {
    const data = { ...user, role: location.pathname.split('/')[1] };
    const result = await api.post('/login', {
      ...data
    });

    if (result?.data?.message === "error") {
      toast.error(result?.data?.content);
    } else {
      login(result.data);
    }
  };

  const role = location.pathname.split('/')[1];

  return (
    <div className="text-white font-poppins" >
      <div className="top_section sm:max-h-[60vh]">
        <div className="md:container mx-auto px-[25px] sm:px-[110px] pt-[46px] pb-[10vh]">
          <Header theme="white" />
          <div className="flex flex-col items-center gap-6 mt-[10vh]">
            <p className="text-4xl sm:text-5xl xl:text-7xl font-inter font-black">{role[0].toUpperCase() + role.slice(1) + " Login"}</p>
            <p className="text-lg text-center">{role ==  "organizer" ? "Log in as an organizer to sell tickets!" : "Log in as a customer to buy tickets!" }</p>

          </div>
        </div>
      </div>
      <div className="py-10" style={{
        background: 'radial-gradient(235.24% 218.89% at 106.31% -18.52%, #CF214E 0%, #1B1B1B 24.48%, #1B1B1B 62.3%, #FEB931 94.98%)'
      }}>
        <div className="text-white flex flex-col justify-center items-center gap-6 p-6 sm:max-w-[600px] mx-auto" >
          <div className=" w-full flex flex-col sm:flex-row items-center justify-end gap-3 sm:gap-6">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Email address" className=" p-2 outline-none bg-[#06060666] w-[300px] sm:w-[400px]" value={user.email} onChange={(e) => setUser((prev) => { return { ...prev, email: e.target.value } })} />
          </div>
          <div className="w-full flex flex-col sm:flex-row items-center justify-end gap-3 sm:gap-6">
            <label htmlFor="pwd">Password</label>
            <input type="password" id="pwd" placeholder="Enter your password" className=" p-2 outline-none bg-[#06060666] w-[300px] sm:w-[400px]" value={user.pwd} onChange={(e) => setUser((prev) => { return { ...prev, pwd: e.target.value } })} />
          </div>
          <p className="p-2">Don't have an account? <a className={role[0].toUpperCase() + role.slice(1) == 'Organizer' ? "text-[#FEB931] font-bold" : "text-[#CF214E] font-bold"} href={"/" + location.pathname.split('/')[1] + "/register"}>Sign up here!</a></p>
          <button type="submit" className="text-white max-w-[250px] px-[22.5px] py-[9px] font-bold shadow-lg" style={role[0].toUpperCase() + role.slice(1) == 'Organizer' ? { "background": "linear-gradient(90deg, #FEB931 100%, rgba(207, 33, 78, 0.00) 113.74%)" } : { "background": "linear-gradient(90deg, #CF214E 100%, rgba(207, 33, 78, 0.00) 113.74%)" }} onClick={handleLogin}>
            Login
          </button>


        </div>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
      <Footer />
    </div>

  );
};