import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Navbar } from "components";

import { adminRoutes } from "routes";

const AdminLayout = () => {
  return (
    <div className="main">
      <Navbar>
        <Suspense>
          <Routes>
            {adminRoutes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              );
            })}
            <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
          </Routes>
        </Suspense>
      </Navbar>
    </div>
  );
};

export default AdminLayout;
