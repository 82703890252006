import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const Events = React.lazy(() => import("pages/events"));
const EventDetail = React.lazy(() => import("pages/eventdetail"));
const Success = React.lazy(() => import("pages/success"));
const About = React.lazy(() => import("pages/about"));
const Contact = React.lazy(() => import("pages/contact"));
const Login = React.lazy(() => import("pages/login"));
const Register = React.lazy(() => import("pages/register"));
const QRScanner = React.lazy(() => import("pages/qr_scanner"));
const NotFound = React.lazy(() => import("pages/notfound"));
const TermsOfService = React.lazy(() => import("pages/termsofservice"));
const RefundPolicy = React.lazy(() => import("pages/refundpolicy"));
const NoRefundPolicy = React.lazy(() => import("pages/norefundpolicy"));

const Profile = React.lazy(() => import("pages/profile"));

const AdminDashboard = React.lazy(() => import("pages/admin/dashboard"));
const AdminEvents = React.lazy(() => import("pages/admin/events"));
const AdminTickets = React.lazy(() => import("pages/admin/tickets"));
const AdminBookings = React.lazy(() => import("pages/admin/bookings"));
const AdminOrganizers = React.lazy(() => import("pages/admin/organizers"));
const AdminTransactions = React.lazy(() => import("pages/admin/transactions"));
const AdminRequests = React.lazy(() => import("pages/admin/requests"));
const AdminSettings = React.lazy(() => import("pages/admin/settings"));

const OrganizerDashboard = React.lazy(() => import("pages/organizer/dashboard"));
const OrganizerEvents = React.lazy(() => import("pages/organizer/events"));
const OrganizerTickets = React.lazy(() => import("pages/organizer/tickets"));
const OrganizerBookings = React.lazy(() => import("pages/organizer/bookings"));
const OrganizerTransactions = React.lazy(() => import("pages/organizer/transactions"));

const routes = [
  { path: "/", exact: true, name: "Home", element: Home },
  { path: "/customer/login", name: "Login", element: Login },
  { path: "/organizer/login", name: "Login", element: Login },
  { path: "/customer/register", name: "Register", element: Register },
  { path: "/organizer/register", name: "Register", element: Register },
  { path: "/events", name: "Events", element: Events },
  { path: "/event/:eventSlug", name: "EventDetail", element: EventDetail },
  { path: "/about", name: "About", element: About },
  { path: "/contact", name: "Contact", element: Contact },
  { path: "/success", name: "Success", element: Success },
  { path: "/qr_scanner", name: "QRScanner", element: QRScanner },
  { path: "/terms-of-service", name: "Terms Of Service", element: TermsOfService },
  { path: "/refund-policy", name: "Refund Policy", element: RefundPolicy },
  { path: "/no-refund", name: "No Refund Policy", element: NoRefundPolicy },
  { path: "/*", name: "NotFound", element: NotFound },
];

const authRoutes = [
  { path: "/profile", name: "Profile", element: Profile },
]

const organizerRoutes = [
  { path: "/dashboard", name: "Dashobard", element: OrganizerDashboard },
  { path: "/events", name: "Events", element: OrganizerEvents },
  { path: "/tickets", name: "Tickets", element: OrganizerTickets },
  { path: "/bookings", name: "Bookings", element: OrganizerBookings },
  { path: "/transactions", name: "Transactions", element: OrganizerTransactions },
];

const adminRoutes = [
  { path: "/dashboard", name: "Dashobard", element: AdminDashboard },
  { path: "/events", name: "Events", element: AdminEvents },
  { path: "/tickets", name: "Tickets", element: AdminTickets },
  { path: "/Bookings", name: "Bookings", element: AdminBookings },
  { path: "/organizers", name: "Organizers", element: AdminOrganizers },
  { path: "/transactions", name: "Transactions", element: AdminTransactions },
  { path: "/requests", name: "Requests", element: AdminRequests },
  { path: "/settings", name: "Settings", element: AdminSettings },
]

export default routes;
export { authRoutes, adminRoutes, organizerRoutes };