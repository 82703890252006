import React, { useEffect, useState } from "react";

import { Header } from "components";
import api from "config/service";
import { toast, Toaster } from 'react-hot-toast';
import { Footer } from "components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useAuth } from 'provider/authProvider';

import DownloadIcon from '@mui/icons-material/Download';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const Profile = () => {
  const [datas, setData] = useState([]);
  const [refunded, setRefunded] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  const confirm = useConfirm();
  const {logout} = useAuth();

  useEffect(() => {
    document.title = 'SriNet | Events';

    if (refunded === 1) {
      const fetchData = async () => {
        const response = await api.get('/user/profile');
        // console.log(response.data)
        setData(response.data);
        const response1 = await api.get('/user/getTicketInfo?selectedTicket=53');
        // console.log("SIMON SAYS", response1.data.ticketInfo[0])
      }
      fetchData().catch(console.error);
    }
  }, [refunded]);

  // const downloadQrcode = async (qrcode) => {
  //   const a = document.createElement("a");
  //   a.download = "myImage.png";
  //   a.href = process.env.REACT_APP_URL + 'qrcodes/' + qrcode + '.png';
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  // "cs_test_a125B4qm9CTIeTN4F6yfTNZIUDkMGzNYAxnGDAVUyF0lGsekXY6QrZAvci"
  // cs_test_b1JgUnNagvMduFfNfbbE3BPy4zClS5IHtHPiRxuwbaQWaQReCiEPXhTAtS
  const downloadQrCodes = (qrCode) => {
    const fetchData1 = async () => {
      // let qrCode = "cs_test_a1txQXf1B1asfNVwqENFon5wWwdvBJqKaIbmsdLoxWrC9JzwC4XhocNolM"
      const requestedQrCode = qrCode;
      const data = { requestedQrCode, ...datas };
      // console.log("Dawggg we finna send ", data)
      const response = await api.post('/user/getTicketsQR', {
        ...data
      });
     //  console.log(response)
      // TODO: Add error handling
      // if (response?.data?.includes("error"))
      try {
        const base64Data = response.data.pdfData;
        const binaryData = new Uint8Array([...atob(base64Data)].map(char => char.charCodeAt(0)));
        const pdfBlob = new Blob([binaryData], { type: 'application/pdf' });

        setTimeout(() => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(pdfBlob);
          link.download = 'downloadedFile.pdf';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    }, 500); // Server needs to chile

      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    }
    fetchData1();
  }

  const requestRefund = async (intent) => {
    setRefunded(0);
    const response = await api.post('/user/request_refund', { payment_intent: intent });
    if (response?.data?.message == "error") {
      toast.error(response?.data?.content);
    } else {
      toast.success('requested successfully');
    }
    setRefunded(1);
  }

  const modalClose = () => {
    setModalOpen(false);
  }

  const modalOpen = () => {
    setModalInfo(datas.user);
    setModalOpen(true);
  }

  const onSaveAccount = async() => {
    const response = await api.post('/user/save',modalInfo);
    setData(response.data);
    setModalOpen(false);
  }

  const onDelete = async() => {
    confirm({ description: "Do you want to delete your account?" })
      .then(async () => {
        const response = await api.get('/user/delete');
        if (response?.data?.message === "error") {
          toast.error('Failed to delete user')
        } else {
          toast.success('Deleted account successfully');
          logout();
        }
      })
      .catch(() => {
        /* ... */
      });
  }

  return (
    <>
      <div className="text-white font-poppins" style={{
        background: 'radial-gradient(235.24% 218.89% at 106.31% -18.52%, #CF214E 0%, #1B1B1B 24.48%, #1B1B1B 62.3%, #FEB931 94.98%)'
      }}>
        <div className="top_section sm:max-h-[60vh]">
          <div className="top_section sm:max-h-[60vh]">
            <div className="md:container mx-auto px-[25px] sm:px-[110px] pt-[46px] pb-[10vh]">
              <Header theme="white" />
              <div className="flex flex-col items-center gap-6 mt-[10vh]">
                <p className="text-3xl sm:text-5xl font-black">Profile</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-white sm:container mx-auto px-[30px] lg:px-[50px] 2xl:px-[200px]" >
          <div className="flex flex-row justify-between">
            <div className="pt-10">
              {/* <button onClick={downloadQrCodes}>Download QR code</button> */}
              <p>Welcome, <span className="font-bold">{datas?.user?.first_name + ' ' + datas?.user?.last_name}!</span></p>
              <p>Here are your recent orders with us.</p>
            </div>
            <div className="pt-10 flex flex-row gap-2">
              <button type="button" className="text-white bg-blue-400 max-w-[250px] px-[10px] md:px-[22.5px] py-[9px] font-bold" onClick={modalOpen}>Edit</button>
            </div>
          </div>
          <div className="pb-10">

            <h1 className="text-lg font-bold my-10">Orders</h1>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Organizer</TableCell>
                    <TableCell align="right">Event</TableCell>
                    <TableCell align="right">Ticket</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Paid</TableCell>
                    <TableCell align="right">Request Refund</TableCell>
                    <TableCell align="right">Download QRcode</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas?.orders?.map((order, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {/* { console.log(`qr code data says: ${order.qrcode}`) } */}
                      <TableCell >{order.organizerName}</TableCell>
                      <TableCell align="right">{order.eventName}</TableCell>
                      <TableCell align="right">{order.ticketName}</TableCell>
                      <TableCell align="right">
                        ${order.ticketPrice.toFixed(2)}
                        {/* {order.absorb_fee ? "(+fee: $" + (order.ticketPrice * 0.029 + 2.3).toFixed(2) + ")" : ""} */}
                      </TableCell>
                      <TableCell align="right">{order.bookedQuantity}</TableCell>
                      <TableCell align="right">
                        ${(order.ticketPrice * order.bookedQuantity).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {
                          order.refund === 1 &&
                          <IconButton>
                            {
                              order.request_refund === 1 ?
                                <HourglassBottomIcon color="secondary" />
                                :
                                <AttachMoneyIcon color={order.request_refund == 0 ? "secondary" : "error"} onClick={(e) => requestRefund(order.payment_intent)} />
                            }
                          </IconButton>
                        }
                      </TableCell>
                      <TableCell align="right">
                          <IconButton onClick={()=>
                          {
                            // console.log("Ok the QR code I'm finna download is: ")
                            downloadQrCodes(order.qrcode);

                            } 
                          }>
                            <DownloadIcon />
                          </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <button type="button" className="text-white bg-orange-400 max-w-[250px] px-[10px] md:px-[22.5px] py-[9px] font-bold" onClick={onDelete}>Delete</button> */}

          </div>

        </div>

        <Footer />
        <Toaster
          position="bottom-center"
          reverseOrder={false}
        />
        <Dialog open={isModalOpen} onClose={modalClose}>
          <DialogTitle>Edit Account</DialogTitle>
          <DialogContent>
            <div className=" w-full flex flex-col sm:flex-row items-center justify-between gap-6 py-1">
              <label htmlFor="first_name">First Name</label>
              <input type="text" id="first_name" placeholder="First name" className=" p-2 outline-none w-[350px] border border-dark" value={modalInfo.first_name} onChange={(e) => setModalInfo((prev) => { return { ...prev, first_name: e.target.value } })} />
            </div>
            <div className=" w-full flex flex-col sm:flex-row items-center justify-between gap-6 py-1">
              <label htmlFor="last_name">Last Name</label>
              <input type="text" id="last_name" placeholder="Last name" className=" p-2 outline-none w-[350px] border border-dark" value={modalInfo.last_name} onChange={(e) => setModalInfo((prev) => { return { ...prev, last_name: e.target.value } })} />
            </div>
            <div className="hidden w-full flex flex-col sm:flex-row items-center justify-between gap-6 py-1">
              <label htmlFor="address">Address</label>
              <input type="text" id="address" placeholder="Address" className=" p-2 outline-none w-[350px] border border-dark" value={modalInfo.address} onChange={(e) => setModalInfo((prev) => { return { ...prev, address: e.target.value } })} />
            </div>
          </DialogContent>
          <DialogActions>
            <button className="bg-white text-danger p-2 outline-none hover:text-primary" onClick={modalClose}>Cancel</button>
            <button className="bg-danger text-white p-2 outline-none hover:bg-primary disabled:bg-gray-400" onClick={onSaveAccount}>Save</button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Profile;