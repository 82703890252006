import { useNavigate } from "react-router";
import { organizerShowDate } from 'config/helper';
import { useState, useEffect } from "react";
import api from "config/service";
import LogoIcon from "assets/img/logo3.png";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@mui/material";

export default function Card(props) {
  const [buyTicketOpen, setBuyTicketOpen] = useState(false);

  const navigate = useNavigate();
  const { event } = props;
  const { date, free_start_date, location, type, name, slug, thumbnail, timezone} = event;
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response2 = await api.get('/getEvent?slug=' + slug);

        if (response2.data?.message) {
          // Handle the case where there's a message
        } else {
          setTickets(response2?.data?.tickets);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error or set an error state if necessary
      }
    };

    fetchData();
  }, []);

  var hasTickets = tickets.length == 0 ? false : true;

  var ticketModalClose;
  var ticketModalOpen = true;
  const goDetail = () => {
    window.location.href = '/event/' + slug;
    // // navigate('/event/' + slug);
    // // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // if (hasTickets){
      
    //   //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      
    // } else {
    //   ticketModalOpen();
    // }
  }

  // ticketModalOpen = () => {
  //   setBuyTicketOpen(true);
  // };

  // ticketModalClose = () => {
  //   setBuyTicketOpen(false);
  // };

  return (
    <>
    <div className="bg-[#06060666] flex flex-col items-start gap-2 shadow-lg  px-[10px] pt-[10px] pb-[20px] cursor-pointer text-sm" onClick={goDetail}>
      <img alt='' src={process.env.REACT_APP_URL + `/uploads/` + thumbnail} className=" shadow-sm  w-full aspect-[4/3] mx-auto" />
      <p className="text-white text-[1.3rem] font-semibold my-3">{name}</p>
      <span className="text-[#EEEEEE] flex flex-row gap-2 items-center"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-clock-4"><circle cx="12" cy="12" r="10" /><polyline points="12 6 12 12 16 14" /></svg>{`${organizerShowDate(date || free_start_date)} ${timezone.split("_@_")[1]}`}</span>
      <span className="text-[#EEEEEE] flex flex-row gap-2 items-middle"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-map-pin"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" /><circle cx="12" cy="10" r="3" /></svg>{type == "venue" ? location : "Online Event"}</span>
    </div>
    <Dialog open={buyTicketOpen} onClose={false}>
    <DialogTitle></DialogTitle>
    <DialogContent className="text-center">
    <img alt='' src={LogoIcon} className="m-auto w-[100px] mb-5" />

      <h1 className="text-2xl text-bold">Tickets Coming Soon! </h1>

      <p className="my-5">Check back at a later date!</p>

    </DialogContent>
    <DialogActions>
      <button className="bg-white text-danger p-2 outline-none hover:text-primary" onClick={ticketModalClose}>Close</button>
    </DialogActions>
  </Dialog>
  </>
  );
}
